#signup {
  margin: 0;
  display: block;
  text-align: center;
}

#signup > label {
  display: block;
  margin: 10px 0;
}

#signup > h2 {
  margin: 10px;
}

.register-p {
  margin: 0;
  width: 50%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.register-p > p {
  margin: 0;
  padding: 15px 3%;
  font-size: 20px;
  text-align: left;
}

.register-p > a {
  padding: 15px 5%;
}

div.signup-div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media only screen (min-width: 600px) {
  #signup {
    width: 60%;
  }

  .register-p > p {
    max-width: 200px;
    flex-basis: 0;
  }
}
