.event-form {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  color: black;
}

.event-form > label {
  display: block;
  padding: 10px 10px;
}

.create-submit {
  margin-top: 10px;
  display: block;
  font-size: 15px;
  font-weight: bolder;
  padding: 5px;
  border: none;
  background-color: white;
}

.create-submit:hover {
}

fieldset.non-desc,
.desc {
  display: flex;
  flex-direction: column;
  border: none;
  font-size: 17px;
}

.non-desc {
  width: 25%;
}

.desc {
  align-self: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.desc > textarea {
  width: 100%;
}

@media only screen and (min-width: 750px) {
  .desc > textarea {
    width: 100%;
    height: 100%;
  }

  fieldset.desc {
    width: 75%;
    height: 100%;
  }
}
