.event-details {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
  flex-wrap: wrap;
}

.events-title {
  color: white;
}

.event-description {
  padding: 15px 0;
  flex-basis: 50%;
}

.ev-desc {
  font-size: 15px;
  width: 100%;
  white-space: pre-wrap;
}

.details {
  padding: 0;
  flex-basis: 200px;
}

.details > ul,
.details > li {
  list-style: none;
  text-indent: none;
}

.details > li {
  padding: 5px 0;
}

.details-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.edit-delete {
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.edit-delete > a {
  text-decoration: none;
  color: black;
}

.edit-delete > a :hover {
  text-decoration: underline;
  color: black;
}

@media only screen and (min-width: 800px) {
  .details {
    margin: 0 auto;
  }

  .details-body {
    flex-direction: row;
  }
}
