.about {
  width: 75%;
  margin: auto;
  text-align: left;
}

.about > p {
  padding: 15px 0;
}

.github-link {
  text-decoration: none;
  color: rgb(59, 41, 14);
}

.github-link:hover {
  text-decoration: underline;
}
