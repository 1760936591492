.loading-div {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.loading-icon {

}

.loading-span {

}