nav {
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 2% 6%;
}

a.nav-link {
  vertical-align: middle;
  font-size: 17px;
  margin: auto 6%;
  text-decoration: none;
  color: black;
  transition: color 0.5s ease;
}

a.nav-link:hover {
  color: rgb(198, 139, 8);
}

div.left-nav,
div.right-nav {
  margin-top: auto;
  margin-bottom: auto;
}

div.right-nav {
  right: 5;
}
