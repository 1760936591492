div.hero {
  font-size: 7vw;
  margin: 3% 6%;
  background: rgb(165, 195, 161);
  background: linear-gradient(
    90deg,
    rgba(165, 195, 161, 1) 51%,
    rgba(255, 254, 173, 1) 77%,
    rgba(255, 241, 178, 1) 100%
  );
}

.hero > h1 {
  padding: 3% 6%;
  color: white;
  letter-spacing: -3.5px;
}

@media only screen and (min-width: 800px) {
  div.hero {
    font-size: 60px;
  }
}
