* {
  box-sizing: border-box;
}

body {
  font: helvetica;
  font-size: 15px;
  margin: 0;
  background-color: white;
  color: rgb(59, 41, 14);
}

.main-routes {
  margin: 5% 5%;
}

p {
  font-size: 20px;
}

a,
button {
  cursor: pointer;
}
