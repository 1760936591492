.Lander {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Lander > p {
  padding: 15px 5%;
  font-size: 20px;
  text-align: left;
}

.enter-button {
  text-decoration: none;
  font-size: 20px;
  color: black;
  margin-top: 30px;
  transition: color 0.5s ease;
}

.enter-button:hover {
  cursor: pointer;
  color: rgb(198, 139, 8);
}
