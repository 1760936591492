div.EventList {
  justify-content: space-around;
}

.events-table {
  background: white;
  padding-bottom: 3%;
}

.header-row {
  display: flex;
  padding: 0 10px;
  background: rgb(175, 207, 171);
  color: white;
}

.header {
  font-weight: bolder;
  font-size: 17px;
  margin: 0;
}

.header,
.table-cell {
  padding: 5px;
}

.table-cell {
  font-size: 12px;
}

.table-row-link {
  padding: 10px 5px;
  display: flex;
  color: black;
  text-decoration: none;
  background-color: rgb(206, 233, 203);
  transition: background-color 0.5s ease;
}

.table-row-link:hover {
  background-color: rgba(105, 105, 105, 0.068);
}

.table-cell:nth-child(1),
.header:nth-child(1) {
  display: flex;
  margin: 0;
  width: 50%;
}

.table-cell:nth-child(2),
.header:nth-child(2) {
  display: flex;
  margin: 0;
  width: 20%;
}

.table-cell:nth-child(3),
.header:nth-child(3) {
  display: flex;
  margin: 0;
  width: 20%;
}

.table-cell:nth-child(4),
.header:nth-child(4) {
  display: flex;
  margin: 0;
  width: 10%;
}

.create-event-link {
  position: fixed;
  text-decoration: none;
  color: rgba(156, 204, 151, 0.822);
  padding: 10px;
  font-size: 5vw;
  bottom: 5%;
  right: 5%;
}

#search {
  padding: 20px 10px;
  color: white;
  background: rgb(165, 195, 161);
  margin-bottom: 25px;
}

@media only screen and (min-width: 800px) {
  .table-cell {
    font-size: 13px;
  }
}

@media only screen and (max-width: 800px) {
  .create-event-link {
    font-size: 15vw;
  }
}